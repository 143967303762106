<template>
  <div>
    <div class="holder">
      <div class="dashboard-holder">
        <SmallNav />
        <div class="container">
          <div class="row">
            <!-- col-md-6 col-lg-6 col-sm-12 col-xl-6 -->
            <div class="col-md-6 col-lg-8 col-sm-12 col-xl-8">
              <div class="navigation">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#application"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      >Rent</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="agrement-tab"
                      data-toggle="tab"
                      href="#agrement"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      >Tenants Agrement</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#tours"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      >Home Tours</a
                    >
                  </li>
                </ul>
              </div>

              <div class="nav-body">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="application"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <!-- Search -->
                    <div class="search">
                      <div class="input-group mb-3">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Enter property pin"
                          v-model="searchPin"
                        />
                        <div class="input-group-append search__btn">
                          <button
                            class="btn"
                            @click="searchProp()"
                            :disabled="!searchPin"
                          >
                            <i v-if="!searchLoader" class="fa fa-search"></i>
                            <i
                              v-if="searchLoader"
                              class="fa fa-spinner fa-spin ml-3 f-12"
                              style="color: #ffffff"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Search ends -->

                    <!-- Property body starts -->
                    <!-- v-if="!hideDiv" -->
                    <div class="property">
                      <div v-if="!hideDiv && noProperty" class="empty__state">
                        <div class="empty__div">
                          <img src="../assets/images/empty-state.svg" alt="" />
                          <p>No property to view at the moment</p>
                          <p>
                            Enter the property pin of your desired property to
                            view rent details
                          </p>
                        </div>
                      </div>

                      <div v-else class="property__options">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-8 col-lg-8 col-sm-12">
                              <div class="row">
                                <div
                                  class="col-md-6 col-lg-6 col-sm-12 col-xl-6"
                                >
                                  <div class="propimg__holder">
                                    <img :src="property.oneimageurl" alt="" />
                                  </div>
                                </div>
                                <div
                                  class="col-md-6 col-lg-6 col-sm-12 col-xl-6"
                                >
                                  <div class="property__details">
                                    <p>{{ property.title }}</p>
                                    <p>
                                      {{ property.city }}, {{ property.state }}
                                    </p>
                                    <p v-if="!property.price">&#8358;0</p>
                                    <p v-else>
                                      &#8358;{{
                                        Number(property.price).toLocaleString()
                                      }}
                                    </p>
                                    <!-- <div class="processing">
                                      <button class="btn">Processing</button>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-sm-12">
                              <div>
                                <div class="btn-group movein-details">
                                  <button
                                    type="button"
                                    class="btn dropdown-btn"
                                  >
                                    View Move-in-details
                                  </button>
                                  <button
                                    disabled
                                    type="button"
                                    class="btn splitbtn dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span class="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#"
                                      >Another action</a
                                    >
                                    <a class="dropdown-item" href="#"
                                      >Something else here</a
                                    >
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#"
                                      >Separated link</a
                                    >
                                  </div>
                                </div>

                                <div class="schedule__tour">
                                  <button class="btn">Schedule a tour</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Warning starts -->
                          <div class="warning">
                            <i class="fa fa-exclamation-triangle"></i>
                            <p>
                              Once you get approved, you must pay the total
                              move-in cost in 24 hours after signing the tenant
                              agreement.
                            </p>
                          </div>
                          <!-- Waring ends -->

                          <!-- Rate begins -->
                          <div class="container rent-value">
                            <div class="row">
                              <!-- col-md-12 col-lg-3 col-sm-12 col-xl-3 -->
                              <div
                                class="col-sm"
                                v-for="(rate, index) in modeofpayment"
                                :key="index"
                              >
                                <label class="rate-label" for="">{{
                                  rate.type
                                }}</label>
                                <div
                                  class="radio-wrap"
                                  :class="[
                                    rate.id === paymode
                                      ? 'activeRadio'
                                      : 'radio-wrap'
                                  ]"
                                  @click="clikedRadio(rate.id)"
                                >
                                  <div
                                    class="custom-control custom-radio custom-control-inline"
                                  >
                                    <input
                                      :value="rate.type"
                                      type="radio"
                                      :id="rate.id"
                                      v-model="mode"
                                      name="customRadioInline1"
                                      class="custom-control-input"
                                    />
                                    <label
                                      :class="[
                                        rate.id === paymode
                                          ? 'rent_content_active'
                                          : 'rent-content'
                                      ]"
                                      class="custom-control-label rent-content"
                                      :for="rate.id"
                                      >&#8358;{{ rate.price }}</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <p>mode of payment{{mode}}</p> -->
                          <!-- Rate ends -->

                          <!-- Added services -->
                          <!-- <div class="added-servic d-none">
                            <div class="col-md-3 mb-3">

                              <select
                                class="custom-select addedService-select"
                                id="validationCustom04"
                              >
                                <option selected disabled value=""
                                  >Added Services</option
                                >
                                <option
                                  class="cleaning"
                                  v-for="item in addedServicesArray"
                                  :value="item.id"
                                  :key="item.id"
                                  >{{ item.addedservicessubcategory }}</option
                                >
                              </select>
                            </div>
                          </div> -->
                          <!-- Added services -->

                          <!-- New added service -->
                          <div
                            class="col-md-12 col-sm-12"
                            v-if="addedServicesArray.length > 0"
                          >
                            <h5 class="added_services_text">
                              Added Service(s)
                            </h5>
                            <div class="addedServicesHolder">
                              <div
                                class="form-group form-check"
                                v-for="(service, index) in addedServicesArray"
                                :key="index"
                              >
                                <input
                                  @change.prevent="toggleAddedServices()"
                                  type="checkbox"
                                  class="form-check-input"
                                  :id="service"
                                  ref="services"
                                  :value="service.id"
                                  v-model="addedServicesId"
                                />
                                <label class="form-check-label" :for="service"
                                  >{{
                                    service.addedservicessubcategory
                                  }}
                                  (&#8358;{{
                                    Number(service.amount).toLocaleString()
                                  }})</label
                                >
                              </div>
                            </div>
                          </div>
                          <!-- New added services ended -->

                          <!-- Property amount summary begins -->
                          <div
                            class="summary"
                            v-for="(prop, index) in modeofpayment"
                            :key="index"
                          >
                            <div
                              class="summary__details"
                              v-if="paymode === prop.id"
                            >
                              <div class="details__summary">
                                <h3>Amount Due</h3>
                                <small
                                  >&#8358;{{
                                    Number(prop.price).toLocaleString()
                                  }}</small
                                >
                              </div>
                              <div class="details__summary">
                                <h3>Admin Fee</h3>
                                <small
                                  >&#8358;{{
                                    Number(prop.admin_fee).toLocaleString()
                                  }}</small
                                >
                              </div>
                              <div class="details__summary">
                                <h3>
                                  Security Deposit
                                  <span class="refundable"> (Refundable)</span>
                                  <span class="tooltiptext"
                                    >An amount paid when you agree to rent a
                                    house, apartment, etc., which it's owner can
                                    keep if you leave before the end of the
                                    agreement or damage the property.</span
                                  >
                                </h3>
                                <small
                                  >&#8358;{{
                                    Number(
                                      prop.security_deposit
                                    ).toLocaleString()
                                  }}</small
                                >
                              </div>

                              <div class="details__summary">
                                <h3>Service Charge</h3>
                                <small
                                  >&#8358;{{
                                    Number(prop.service_charge).toLocaleString()
                                  }}</small
                                >
                              </div>
                              <div class="details__summary">
                                <h3>Legal Fee</h3>
                                <small>&#8358;0.00</small>
                              </div>
                              <div class="details__summary">
                                <h3>Agent Fee</h3>
                                <small>&#8358;0.00</small>
                              </div>
                              <div class="money__summary">
                                <h3>Move-in Total</h3>
                                <small class="d-none">{{
                                  (amount = prop.total + summedData)
                                }}</small>
                                <small
                                  >&#8358;{{
                                    Number(
                                      prop.total + summedData
                                    ).toLocaleString()
                                  }}</small
                                >
                              </div>
                            </div>
                          </div>
                          <!-- Property amount summary ends -->
                          <div class="paynow">
                            <!-- <button @click="payNow()" class="btn">
                              PAY NOW
                            </button> -->
                            <button
                              @click="trigerRentPayment(property)"
                              class="btn"
                            >
                              PAY NOW
                              <i
                                class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                                v-if="loader"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="empty__state d-none">
                      <div class="empty__div">
                        <img src="../assets/images/empty-state.svg" alt="" />
                        <p>No property to view at the moment</p>
                        <p>
                          Enter the property pin of your desired property to
                          view rent details
                        </p>
                      </div>
                    </div>
                    <!-- Property body ends -->
                  </div>

                  <div
                    class="tab-pane fade"
                    id="tours"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <p class="no-tours">Tours not available at the moment!!!</p>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="agrement"
                    role="tabpanel"
                    aria-labelledby="agrement-tab"
                  >
                    <!-- <p class="no-tours">Agrement Tag</p> -->
                    <div class="agrement">
                      <div class="contianer">
                        <div class="col-md-5 agrement_header">
                          <h6>
                            {{ agrementdata.data }}
                            <!-- This Tenancy Agreement is made this 7th day of
                            Setepmber 2021 , by and between: Tenant (“Tenant
                            name”) and Shelta The parties agree as follows: -->
                          </h6>
                        </div>
                      </div>

                      <div class="container">
                        <p class="agrement__body"></p>

                        <div class="col-auto agg-holder">
                          <div class="custom-control custom-checkbox mr-sm-2">
                            <input
                              v-model="checkagrement"
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlAutosizing"
                            />
                            <label
                              class="custom-control-label agg-label"
                              for="customControlAutosizing"
                            >
                              I admit to have read the tenency agreement, and i
                              agree to the stated terms and conditions.
                            </label>
                            <p class="checkBoxx">{{ checkBox }}</p>
                          </div>
                        </div>

                        <div class="agreement__btn">
                          <button
                            @click="signAgreement()"
                            class="btn btn-block"
                          >
                            Sign Agreement
                            <i
                              class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                              v-if="loader1"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 col-xl-4">
              <div class="container profile">
                <h1>{{ profileData.percentage_complete }}%</h1>
                <p>Profile complete</p>

                <progress
                  id="file"
                  :value="profileData.percentage_complete"
                  max="100"
                >
                  {{ profileData }}%
                </progress>
                <div
                  class="approved-text"
                  v-if="profileData.percentage_complete != 100"
                >
                  <p>Get approved by completing your profile now.</p>
                </div>

                <div class="requirement">
                  <h3>
                    Requirements
                    <div>
                      <img src="../assets/images/verify.svg" alt="" />
                      <span
                        class="verify-text"
                        v-if="userVerified.verified === '0'"
                        >Not verified</span
                      >
                      <span
                        class="verify-text-green"
                        v-if="userVerified.verified === '1'"
                        >Verified</span
                      >
                    </div>
                  </h3>
                </div>
                <div class="requirement-list">
                  <p class="show-more" @click="requirements = !requirements">
                    <span v-if="!requirements">Show more</span>
                    <span v-if="requirements">Show less</span>
                    <i
                      class="fa fa-arrow-down"
                      :class="requirements ? 'arrow_up' : 'fa-arrow-down'"
                    ></i>
                  </p>
                  <div class="req-list" v-if="requirements">
                    <ul>
                      <li>
                        Bio data

                        <span v-if="profileStatus.biodata === 'true'" class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span v-if="profileStatus.biodata === 'false'" class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        Occupation Details

                        <span
                          v-if="profileStatus.occupationdetails === 'true'"
                          class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.occupationdetails === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        Means of ID / Valid ID

                        <span v-if="profileStatus.meansofid === 'true'" class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.meansofid === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        Next of Kin Details

                        <span v-if="profileStatus.nextofkin === 'true'" class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.nextofkin === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        Guarantor’s Details

                        <span
                          v-if="profileStatus.gurantordetails === 'true'"
                          class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.gurantordetails === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        Financial Status

                        <span
                          v-if="profileStatus.averageincome === 'true'"
                          class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.averageincome === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        In case of emergency info
                        <span
                          v-if="profileStatus.emergency_details === 'true'"
                          class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="profileStatus.emergency_details === 'false'"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <li>
                        <span
                          style="cursor: pointer; font-weight: bold;"
                          @click="membership()"
                          >Subscription</span
                        >
                        <span v-if="userVerified.subscribed === 'YES'" class=""
                          ><i class="fa fa-check check-good"></i
                        ></span>
                        <span
                          v-if="userVerified.subscribed === 'NO'"
                          style="cursor: pointer"
                          @click="membership()"
                          class=""
                          ><i class="fa fa-times check-bad"></i
                        ></span>
                      </li>
                      <p class="requirement-action">
                        <router-link to="/dashboard/biodata">
                          Edit Profile
                        </router-link>
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button trigger modal -->

    <!-- Modal to fund wallet begins -->
    <div
      class="modal fade fundme d-none"
      id="fundme"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="close_bank">
            <h6>Payment</h6>
            <small data-dismiss="modal" aria-label="Close">x</small>
          </div>
          <div class="modal-body">
            <div class="funds_wrap">
              <h5 class="fund_title">Amount Payable</h5>
              <h4 class="fund_amount" v-if="amount">
                &#8358;{{ Number(amount).toLocaleString() }}
              </h4>
              <div class="funds_options">
                <div class="fund_type">
                  <paystack
                    class="paystackBtn"
                    :amount="amount * 100"
                    :email="email"
                    :paystackkey="PUBLIC_KEY"
                    :reference="reference"
                    :callback="processPayment"
                    :close="close"
                  >
                    <div class="paystack_div">
                      <img src="../assets/images/block.svg" alt="" />
                      <small>
                        Paystack
                      </small>
                    </div>
                  </paystack>
                </div>

                <div class="fund_type">
                  <button class="btn btn-block bank" @click="payFromBank()">
                    <img
                      src="../assets/images/double-arrow.svg"
                      alt="transfer"
                    />
                    Bank Transfer
                  </button>
                </div>

                <div class="fund_type">
                  <button class="btn btn-block wallet_withdraw" disabled>
                    <img
                      src="../assets/images/pay-wallet.svg"
                      alt="transfer"
                      width="20%"
                    />Pay from wallet
                  </button>
                </div>
                <!-- <div class="fund_type">
                  <button class="btn btn-block paystack" disabled>
                    <img
                      src="../assets/images/block.svg"
                      alt="transfer"
                    />Paystack
                  </button>
                </div> -->

                <div class="fund_type">
                  <button class="btn btn-block flutter" disabled>
                    <img
                      src="../assets/images/flutter.svg"
                      alt="transfer"
                    />Flutterwave
                  </button>
                </div>
                <div class="fund_type">
                  <button class="btn btn-block enaira" disabled>
                    <img
                      src="../assets/images/e-naira.svg"
                      alt="transfer"
                    />e-Naira
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to Fund wallet -->

    <!-- Modal to validate Agrement starts -->
    <div
      class="modal fade"
      id="agree"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header opt-head">
            <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> -->
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="modal_veri_text">
              A verification code has been sent to your registered email.
            </p>
            <h6 class="enter_code_text">Kindly enter the code here</h6>
            <!-- <h5 class="otp__text">{{OTP}}</h5> -->
            <div class="otp-holder">
              <input
                @keyup="keytab($event)"
                v-model="one"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                maxlength="1"
              />
              <input
                @keyup="keytab($event)"
                v-model="two"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                maxlength="1"
              />
              <input
                @keyup="keytab($event)"
                v-model="three"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                maxlength="1"
              />
              <input
                @keyup="keytab($event)"
                v-model="four"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                maxlength="1"
              />
              <input
                @keyup="keytab($event)"
                v-model="five"
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                maxlength="1"
              />
            </div>

            <div class="otp__btn">
              <button class="btn" @click="concatOtp()">
                Send OTP
                <i
                  class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                  v-if="loader"
                ></i>
              </button>
              <p>
                Didn’t get the code?
                <span @click="signAgreement">Resend Code</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal to validate Agrement ends -->

    <!-- <component class="more-content" :is="PaymentModal"></component> -->
    <PaymentModal
      @paystackReference="dataFromChild($event)"
      @action="dataFromChild($event)"
      :dataForPayment="modalData"
      :responseData="resData"
      v-model="paymentM"
    ></PaymentModal>
    <!-- <component class="" :header="headerCaption" :is="PaymentModal"></component> -->
    <subscriptionModal />
    <div v-if="hideDiv">
      <PageLoader></PageLoader>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import paystack from "vue-paystack";
import { staticdata } from "../../src/data";
import PaymentModal from "./PaymentModal.vue";
import PageLoader from "./PageLoader.vue";
import subscriptionModal from "../components/SubscriptionModal.vue";

export default {
  name: "RentApplication",
  components: {
    SmallNav,
    paystack,
    PaymentModal,
    PageLoader,
    subscriptionModal
    // FooterMain,
  },
  data() {
    return {
      isActiveRent: false,
      loader: false,
      loader1: false,
      searchLoader: false,
      requirements: false,
      modeofpayment: [],
      property: {},
      checkBox: "",
      one: "",
      two: "",
      three: "",
      four: "",
      five: "",
      noProp: "",
      mode: "Annually",
      paymode: 1,
      agrementdata: "",
      agreementBody: "",
      agrementData: "",
      profileData: "",
      userVerified: "",
      profileStatus: "",
      concateAggrment: "",
      fulldata: "",
      searchPin: "",
      propertyPin: "",
      file: "",
      OTP: "",
      checkagrement: "",
      addedServicesArray: [],
      addedServicesId: [],
      message: "",
      token: "",
      error: false,
      fileselected: false,
      filePath: "",
      uploading: false,
      progress: 0,
      headerText1: true,
      headerText2: false,
      headerText3: false,
      userid: "",
      percentageFull: false,
      hideDiv: false,
      summedData: "",
      amount: "",
      noProperty: false,
      // rates: [
      //   {
      //     name: "Annual Rate",
      //     value: "3,000,000.00",
      //     id: 1
      //   },
      //   {
      //     name: "Bi-Annual Rate",
      //     value: "1,650,000.00",
      //     id: 2
      //   },
      //   {
      //     name: "Quarterly Rate",
      //     value: "800,000",
      //     id: 3
      //   },
      //   {
      //     name: "Monthlyl Rate",
      //     value: "240,000",
      //     id: 4
      //   }
      // ],
      // PUBLIC_KEY: staticdata.paystackkeytest,
      PUBLIC_KEY: staticdata.paystackkeylive,
      email: "",
      full_name: "",
      reference: "",
      modalData: {},
      paymentM: "",
      resData: "",
      subscribed: ""
    };
  },
  // beforeMount() {
  //   this.hideDiv = true;
  //   this.noProperty = false;
  // },
  mounted() {
    this.userid = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.email = this.user.email;
    this.full_name = this.user.firstname + this.user.lastname;
    // console.log("Useridddddddddddddd", this.userid);
    this.fetchProperty();
    // this.fetcheAddedServices();
    this.fetchPropertyAgrement();
    this.usercompleteprofile();
  },
  created() {
    this.$root.$refs.A = this;
    this.$root.$refs.C = this;
  },
  methods: {
    membership() {
      if (this.subscribed === "NO" || this.subscribed === false) {
        this.$root.$refs.D.openSubModal();
      } else {
        return this.$store.commit("setApiFailed", "You are already subscribed");
      }
    },
    trigerRentPayment: function(propInfo) {
      propInfo.paymodeid = this.paymode;
      propInfo.addedServicesId = JSON.stringify(this.addedServicesId);
      if (this.percentageFull == true) {
        this.fundLoader = true;
        this.modalData.amount = 0;
        this.modalData.payableAmount = Number(this.amount);
        this.modalData.subtitle = "Payment Amount";
        this.modalData.paymentType = "rentpayment";
        this.modalData.headerCaption = "RENT PAYMENT";
        this.modalData.headerBackground = "#0033ea";
        // this.modalData.paymentURL = "";
        this.$root.$refs.A.openPaymentOptionsModal(propInfo);
      } else {
        // $("#fundme").modal("hide");
        return this.$store.commit("setApiFailed", "Profile not completed");
      }
    },
    dataFromChild(data) {
      // console.log("Data from child", data);
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "paystack"
      ) {
        this.payMyRentPaystack(data);
      }
      if (
        data.action === true &&
        this.modalData.paymentType === "rentpayment" &&
        data.type === "payfromsafe"
      ) {
        this.payMyRentWithSafe();
      }
      // this.reference = data;
      // this.setupScheduleFinally();
    },
    async payMyRentWithSafe() {
      // alert("pay with safe");
      this.resData = "loading";
      // this.loader = true;
      if (
        this.userid === "" ||
        this.property.id === "" ||
        this.paymode === ""
      ) {
        return this.$store.commit("setApiFailed", "Payment parameters missing");
      } else {
        try {
          // payrentviasafe
          await Api.postRequest(`applyforrentsafe`, {
            userid: this.userid,
            propertyid: this.property.id,
            modeofpaymentid: this.paymode,
            addedservicesids: JSON.stringify(this.addedServicesId)
          })
            .then(res => {
              console.log("Rent Payment from pay with safe response", res);
              this.loader = false;
              this.resData = "response";
              if (res.data.success) {
                return this.$store.commit(
                  "setApiSuccess",
                  "Payment is successful"
                );
              }
              this.resData = "";
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
              console.log("err", err);
              // return this.$store.commit("setApiFailed", "Error occured");
            });
        } finally {
          // console.log("closed")
        }
      }
    },

    async payMyRentPaystack(data) {
      this.loader = true;
      this.resData = "loading";
      if (
        this.userid === "" ||
        this.property.id === "" ||
        this.paymode === ""
      ) {
        this.loader = false;
        return this.$store.commit("setApiFailed", "Payment parameters missing");
      } else {
        try {
          // payrentviasafe
          await Api.postRequest(`applyforrent`, {
            userid: this.userid,
            propertyid: this.property.id,
            modeofpaymentid: this.paymode,
            addedservicesids: JSON.stringify(this.addedServicesId),
            referencecode: data.ref
          })
            .then(res => {
              this.loader = false;
              // console.log("Rent Payment from Paystack response", res);
              this.resData = "response";
              if (res.data.success) {
                return this.$store.commit(
                  "setApiSuccess",
                  "Payment is successful"
                );
              }
              if (res.data.error) {
                return this.$store.commit(
                  "setApiFailed",
                  "Failed to make payment"
                );
              }
              this.resData = "";
            })
            .catch(err => {
              this.loader = false;
              console.log("err", err);
              // return this.$store.commit("setApiFailed", "Error occured");
            });
        } finally {
          // console.log("closed")
        }
      }
    },

    processPayment(res) {
      // console.log("clikedd", this.property);
      const ref = res.reference;
      if (res.status === "success") {
        $("#fundme")
          .removeClass("fade")
          .modal("hide");
        Api.postRequest(`applyforrent`, {
          userid: this.userid,
          propertyid: this.property.id,
          modeofpaymentid: this.paymode,
          addedservicesids: JSON.stringify(this.addedServicesId),
          // id: this.paymentData.nextpaymentid,
          // amount: this.paymentData.nextpayment,
          referencecode: ref
        })
          .then(res => {
            // console.log("Subscription response", res);
            if (res.data.success === "success") {
              return this.$store.commit(
                "setApiSuccess",
                "Payment is successful"
              );
            }
          })
          .catch(err => {
            console.log("err", err);
            // return this.$store.commit("setApiFailed", "Error occured");
          });
      }
      // console.log("refrence key", res, ref);
      // window.alert("Refrence key:", this.reference);
    },
    // Paystack close function or cancel payment
    close: () => {
      // console.log("You closed checkout page");
    },
    toggleAddedServices() {
      const datas = this.addedServicesArray.filter(ids =>
        this.addedServicesId.includes(ids.id)
      );
      this.summedData = datas.reduce((sum, current) => {
        return sum + parseInt(current.amount);
      }, 0);
    },
    payNow() {
      // this.amount = amount;
      if (this.percentageFull === true) {
        $("#fundme")
          .addClass("fade")
          .modal("show");
      } else {
        $("#fundme").modal("hide");
        return this.$store.commit("setApiFailed", "Profile not completed");
      }
    },

    concatOtp() {
      const theOtp = this.one.concat(
        this.two,
        this.three,
        this.four,
        this.five
      );
      this.token = theOtp;
      // console.log('one OTP', theOtp);
      this.sendOTP();
    },
    // showMore() {
    //   if (this.lastpayment === 'show') {
    //       this.displayAmount = true;
    //   } else {
    //     this.displayAmount = false;
    //   }
    // },
    usercompleteprofile: async function(pin) {
      // console.log('Property ID', id);
      const id = JSON.parse(localStorage.getItem("shelta-user")).id;
      try {
        await Api.getRequest(`getusercompleteprofile/${id}`)
          .then(res => {
            this.profileData = res.data;
            this.fulldata = res.data.fulldata;
            this.profileStatus = res.data.profile_sections_check;
            this.userVerified = res.data.user_object;
            this.subscribed = res.data.user_object.subscribed;
            if (res.data.percentage_complete === 100) {
              this.percentageFull = true;
            } else {
              this.percentageFull = false;
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element

      const target = event.target || event.srcElement;
      // const id = target.id;
      // console.log(nextInput); // prints undefined
      if (nextInput === null) {
        // check the maxLength from here
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    },
    async sendOTP() {
      // this.token = theOtp;
      this.loader = true;
      // console.log('OTP', this.token);
      try {
        await Api.postRequest(`validateagreementtoken`, {
          userid: this.userid,
          token: this.token
        })
          .then(res => {
            // console.log(res);
            this.loader = false;
            $("#agree").modal("hide");
            this.$store.commit("setApiSuccess", "Agreement signed");
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    async signAgreement() {
      this.loader1 = true;
      this.concateAggrment = this.agrementData.concat(this.agreementBody);
      // console.log("Agrement tm", this.checkagrement);
      if (this.checkagrement === false || this.checkagrement === "") {
        // this.$store.commit("setApiWarning", "Check the box");
        this.checkBox = "Please check the box";
        this.loader1 = false;
      } else {
        this.checkBox = "";
        try {
          await Api.postRequest(`signaggreement`, {
            userid: this.userid,
            propertyid: this.propertyPin,
            agreement: this.concateAggrment
          })
            .then(res => {
              // console.log("Signed agreement", res);
              this.OTP = res.data.message.otp;
              // console.log("OTP", this.OTP);
              this.$store.commit("setApiSuccess", "OTP has been sent to you");
              this.loader1 = false;
              $("#agree").modal("show");
            })
            .catch(err => {
              console.log(err);
              // this.loader = false;
              this.loader1 = true;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile(event) {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.file = file;
      this.fileName = file.name;
      // console.log("fileeeeeeeeeeee", this.file);
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      // this.switchView(2)
      const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        // this.loaderM = false;
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("userid", this.userid);
      formData.append("propertyid", this.property.id);
      formData.append("modeofpaymentid", this.paymode);
      formData.append("addedservicesids", JSON.stringify(this.addedServicesId));
      // this.$store.commit("setApiWarning", 'Uploading...');

      // console.log("Sending data", Object.values(this.addedServicesId));
      try {
        this.uploading = true;
        await Api.postRequest(`applyforrent`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            // console.log("Uploaded file", res);
            // this.$store.commit("setApiWarning", '');
            this.$store.commit("setApiSuccess", "Success");
            this.switchView(2);
            this.uploading = false;
            // this.message = "File has been uploaded";
            // this.file = '';
            // this.error = false;
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
    },

    clikedRadio: function(event) {
      const radio = document.getElementById(event);
      radio.checked = true;
      this.paymode = event;
    },
    fetchProperty: async function() {
      const id = this.$route.params.id;
      this.searchPin = id;
      this.hideDiv = true;
      this.fetchPropertyAgrement();
      try {
        await Api.getRequest(`searchpropertybypin/${id}`)
          .then(res => {
            this.hideDiv = false;
            this.noProperty = false;
            // console.log("Single property", res);
            if (this.searchPin === "undefined" || this.searchPin == "id") {
              this.searchPin = "";
            }
            if (
              (res.data.error === "property not found") &
              (res.data.message === "property not found")
            ) {
              // this.hideDiv = false;
              this.noProperty = true;
            }
            if (res.data.success) {
              this.addedServicesArray = res.data.property.addedservices;
              this.noProperty = false;
              this.hideDiv = false;
              this.property = res.data.property;
              this.propertyPin = res.data.property.id;
              this.modeofpayment = res.data.property.tenantmodeofpayments;
            }
          })
          .catch(err => {
            this.hideDiv = false;
            console.log(err);
            // this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    searchProp: async function() {
      this.searchLoader = true;
      this.hideDiv = true;
      this.$router.push({ path: `${this.searchPin}` }).catch(() => {});
      this.$route.params.id = this.searchPin;
      const id = this.searchPin;

      try {
        await Api.getRequest(`searchpropertybypinwhilerenting/${id}`)
          .then(res => {
            // console.log("Search response", res);
            this.hideDiv = false;
            this.searchLoader = false;
            // this.noProperty = false;
            if (res.data.error) {
              this.hideDiv = false;
              this.$store.commit("setApiFailed", res.data.message);
            } else {
              this.noProp = res.data.message;
              this.hideDiv = false;
              this.noProperty = false;
              this.addedServicesArray = res.data.property.addedservices;
              this.property = res.data.property;
              this.modeofpayment = res.data.property.tenantmodeofpayments;
            }
            if (
              res.data.error === "property not found" &&
              res.data.message === "property not found"
            ) {
              this.hideDiv = false;
              this.noProperty = true;
            }
          })
          .catch(err => {
            this.searchLoader = false;
            this.hideDiv = false;
            console.log(err);
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
      // console.log('Property ID', id);
    },
    fetchPropertyAgrement: async function() {
      if (this.searchPin != "") {
        const id = this.searchPin;
        try {
          await Api.getRequest(
            `getagreementbyuseridandpropertypin/${this.userid}/${id}`
          )
            .then(res => {
              this.agrementdata = res.data;
              this.agrementData = res.data.data;
              this.agreementBody = res.data.body;
            })
            .catch(err => {
              console.log(err);
              // this.loader = false;
              // this.uploading = true;
            });
        } finally {
          // console.log("Closed connection");
        }
      } else {
        this.$store.commit("setApiFailed", "Enter property pin");
      }

      // console.log('Property ID', id);
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}

@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

#twoo {
  display: none;
}

#threee {
  display: none;
}

.navigation {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // height: 84px;
  margin-top: 1rem;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-link {
  padding: 1.6rem 2.5rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.nav-link.active {
  background-color: transparent;
  border-bottom: 5px solid $primary;
  margin-left: 1.5rem;

  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.nav-tabs {
  border-bottom: none;
}

/* Serach field*/
.search {
  padding-top: 1rem;

  div {
    input {
      height: 45px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px !important;
      box-shadow: none;

      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 50px;
      align-items: center;
      color: $black;
    }
  }
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 50px;
  align-items: center;
  color: #c4c4c4;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 50px;
  align-items: center;
  color: #c4c4c4;
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 50px;
  align-items: center;
  color: #c4c4c4;
}

.search__btn {
  button {
    background: $primary;
    width: 108px;
    height: 45px;
    margin-left: 0.5rem;
    border-radius: 5px !important;
    outline: 0;
    box-shadow: none;
    z-index: 1 !important;
  }
}

.fa-search {
  color: $secondary;
}

/* Search ends */

/* Property body starts */
.property {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.empty__div {
  padding: 1rem;

  img {
    width: 100%;
    max-width: 470.58px;
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    color: #000000;
  }
}

.property__options {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.propimg__holder {
  border-radius: 5px !important;

  img {
    border-radius: 5px !important;
    // min-width: 191px;
    max-width: 100%;
    width: 100%;
    max-height: 145px;
    height: 100%;
  }
}

.property__details {
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    // padding-top: 0.7rem;
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    margin-top: 5px;
    text-align: left;
  }

  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    margin-top: 5px;
    text-align: left;
  }
}

.processing {
  text-align: left;
  padding: 0.9rem 0;

  button {
    background: rgba(212, 212, 212, 0.38);
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 20px;

    width: 173px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
}

// .movein-details {
//   // margin-top: 2rem
// }

.close_bank {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;

  h6 {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }

  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.fund_title {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $black;
}

.fund_amount {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 33px;
  color: $black;
}

.funds_wrap {
  // border: 1px solid #E5E5E5;
  box-sizing: border-box;
  margin: 40px 52px;
  border-radius: 10px;
}

.funds_options {
  margin: 10px 22px;
}

.fund_type {
  padding: 5px 15px;

  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;

    img {
      padding-right: 10px;
    }
  }

  .bank {
    background: $primary;
    outline: 0;
    box-shadow: none;
  }

  .wallet_withdraw {
    background: #1d95c8;
    outline: 0;
    box-shadow: none;
  }

  .paystack {
    background: #56c1f2;
    outline: 0;
    box-shadow: none;
  }

  .flutter {
    background: $yellow;
    outline: 0;
    box-shadow: none;
  }

  .enaira {
    background: #16780d;
    margin-bottom: 5px;
    outline: 0;
    box-shadow: none;
  }

  .paystackBtn {
    color: $secondary;
    border: none;
    background: #56c1f2;
    // background: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // margin-left: 10px;
    box-shadow: none;
    outline: 0;
    // max-width: 262px;
    width: 100%;
    border-radius: 5px;
    font-size: 15px !important;

    // height: 51px;
    small {
      font-size: 15px;
    }
  }

  .paystackBtn:hover {
    color: #1d3c4b;
    font-size: 17px;
    cursor: pointer;
  }
}

// Bank transfer upload modal
.payment_header_holder {
  background: $primary;
}

.payment-header {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $secondary;
}

.close_times {
  color: #ea0000;
  background: $secondary;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0px 6px;
}

.added_services_text {
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: $black;
  text-align: left;
  margin-top: 50px;
}

.addedServicesHolder {
  display: flex;
  gap: 15px;
  margin-top: 15px;

  div {
    label {
      font-family: Gotham;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: -0.05em;
      text-align: left;
      color: $black;
      // color: #4258aa;
    }
  }
}

.dropdown-btn {
  background: $primary;
  color: $secondary;
  border-radius: 5px;
  // width: 165px;
  height: 45px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.splitbtn {
  background: #efefef;
  border: 0.5px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.38);
  border-radius: 0px 5px 5px 0px;
  width: 31px;
  height: 45px;
  box-shadow: none;
}

.schedule__tour {
  padding-top: 1.5rem;
  text-align: right;

  button {
    width: 144px;
    height: 60px;
    background: linear-gradient(0deg, #ffffff, #ffffff),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    color: $black;
  }
}

// Warning
.warning {
  margin-top: 1.5rem;
  background: rgba(255, 114, 94, 0.1);
  border: 0.5px solid #ff725e;
  border-radius: 5px;
  height: auto;
  // height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 10px;

  i {
    color: #ff725e;
    width: 1.5rem;
  }

  p {
    padding: 1rem 2rem 0 2rem;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: $black;
  }
}

// Warning

/* Rate of amount of property */
.rent-value {
  padding-top: 3rem;
}

.rate-label {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.05em;
  display: flex;
  color: $black;
}

.radio-wrap {
  // width: 156.68px;
  height: 45px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;

  div {
    padding-top: 0.65rem;
  }
}

.activeRadio {
  border: 1px solid $primary;
  // width: 156.68px;
  height: 45px;
  box-sizing: border-box;
  border-radius: 5px;

  div {
    padding-top: 0.65rem;
  }
}

.rent-content {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #c4c4c4;
}

.rent_content_active {
  color: $primary;
}

/* Rate of amount of property*/

// Services
.added-service {
  padding-top: 2rem;
}

.addedService-select {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  // line-height: 30px;
  align-items: center;
  text-align: center;
  // width: 77px;
  height: 30px;
  color: $black;
}

/* Property amount summary begins*/
// .summary {
// }
.summary__details {
  padding-top: 3rem;

  .details__summary {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #c4c4c4;
    margin: 0 15px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
  }

  .money__summary {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    margin: 0 15px;
    padding-bottom: 0.5rem;
    padding-top: 1rem;

    small {
      font-weight: bold;
    }
  }

  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    // align-items: center;
    // text-align: center;
    color: #000000;
  }

  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    // align-items: center;
    // text-align: center;
    color: #000000;
  }
}

.refundable {
  color: #c4c4c4;
  padding-left: 0.5rem;
  font-size: 12px;
  position: relative;
  cursor: pointer;
}

// .tooltip {
//   position: relative;
//   display: inline-block;
//   border-bottom: 1px dotted black;
// }

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background: #f2f5fe;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 19px;
  /* or 237% */

  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #000000;
  text-align: center;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  top: 69%;
  left: 32%;
  margin-left: -60px;
}

.refundable + .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.refundable:hover + .tooltiptext {
  visibility: visible;
}

.paynow {
  text-align: right;

  button {
    background: $primary;
    border-radius: 5px;
    color: $secondary;
    outline: 0;
    box-shadow: none;
    // width: 100%;
    // height: fit-content;
    width: 166px;
    height: 40px;

    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
}

/* Proprty amount summary ends */

/* Property Body ends */

/* Tours section*/
.no-tours {
  padding-top: 4rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
}

/* Tours section ends*/

/* Profile section begins */
.profile {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  // height: 388px;
  margin-top: 1rem;

  h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    padding-top: 73px;
    // line-height: 30px;
    // align-items: flex-end;
    color: rgba(0, 0, 0, 0.7);
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    color: $black;
    padding-top: 1rem;
  }
}

.verify-text {
  font-size: 12px;
  margin-left: 0.3rem;
  color: red;
}

.verify-text-green {
  font-size: 12px;
  margin-left: 0.3rem;
  color: #2fc643;
}

.requirement {
  padding: 20px 0rem 30px 0;

  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: #000000;
    border-bottom: 1px solid #c4c4c4;
  }
}

.req-list {
  ul {
    padding-left: 0;
    padding-bottom: 1rem;

    li {
      list-style-type: none;
      text-align: left;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 50px;
      // align-items: center;
      // text-align: center;
      color: $black;
      display: flex;
      justify-content: space-between;
    }
  }
}

.requirement-action {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 30px;

  // align-items: center;
  // text-align: center;
  text-decoration-line: underline;
  color: $primary;
  cursor: pointer;
}

.check-good {
  background: $primary;
  padding: 0.2rem;
  // border-radius: 50px;
  color: $secondary;
  // margin-left: 0.5rem;
}

.check-bad {
  background: red;
  padding: 0.2rem 0.3rem;
  // border-radius: 50px;
  color: $secondary;
  // margin-left: 0.5rem;
}

.approved-text {
  padding-top: 1.5rem;

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    text-align: left;
    color: $black;
    // padding: 0 8px;
  }
}

.show-more {
  text-align: left;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 0;
  padding-bottom: 15px;
  color: $primary;
  cursor: pointer;
}

.show-more:hover {
  color: #203c9f;
}

.fa-arrow-down {
  color: $primary;
  margin-left: 10px;
}

.arrow_up {
  transform: rotate(180deg);
}

/* Profile section ends */

// First step starts here
.number__holder {
  text-align: left;
  display: flex;

  p:first-child {
    background: $primary;
    // padding: 0.3rem 0.69rem;
    border-radius: 50px;
    color: $secondary;
    text-align: center;
    align-items: baseline;
    margin-right: 1rem;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 11px;
    // width: 2rem;
    // height: 1.5rem;
  }

  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: $black;
  }
}

.sheltaAccount__details {
  // margin: auto;
  background: rgba(64, 123, 255, 0.46);
  margin: 0 -16px 15px -16px;

  div {
    display: flex;
    justify-content: space-between;
    // margin: 0 11rem;
    margin: 0 3.5rem;
    padding-top: 10px;

    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: left;
    }

    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: right;
    }
  }
}

.next-btn {
  background: $primary;
  border-radius: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $secondary;
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: none;
}

.desclaimer__text {
  padding-top: 1rem;

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: $primary;
  }
}

.modal-foot {
  border-top: none;
}

// Firts modal steps ends

// Secosn modal steo start
.info__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $black;
}

.dateOf__payment {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    color: $black;
  }

  input {
    width: 100%;
    border-bottom: 0.5px solid #6c6c6c !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.upload__file {
  .dropzone {
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    padding: 50px 5px;
    min-height: 200px;
    cursor: pointer;
    position: relative;

    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }

    .browse__span {
      color: $primary;
    }
  }
}

.dropzone:hover {
  background-color: lightgrey;
}

.input-file {
  opacity: 0;
  width: 100%;
  left: 0%;
  top: 0%;
  height: 215px;
  position: absolute;
  cursor: pointer;
  z-index: 1000;
}

.actual_image {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  position: absolute;
  left: 0%;
  cursor: pointer;
}

.third-step {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    // display: flex;
    // align-items: flex-end;
    text-align: center;
    color: $black;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.call-btn {
  background: #f2f5fe;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;

  // width: 154px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  box-shadow: none;
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  color: $black;
  padding: 6px 26px;

  a {
    img {
      width: 27.01px;
      margin-right: 1rem;
    }
  }
}

.back__arrow {
  padding-top: 0.7rem;
  cursor: pointer;
}

// Second modal step ends

.payment-header {
  margin: auto;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $black;
  margin-right: -1rem;
}

/* Make circles that indicate the steps of the form: */
.dot-holder {
  text-align: center;
  margin-top: 9px;
  margin-bottom: 30px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  // opacity: 1;
  background-color: $primary;
}

.step.finish {
  background-color: #04aa6d;
}

// #regForm {
//   background-color: #ffffff;
//   margin: 100px auto;
//   font-family: Raleway;
//   padding: 40px;
//   width: 70%;
//   min-width: 300px;
// }

// h1 {
//   text-align: center;
// }

// input {
//   padding: 10px;
//   width: 100%;
//   font-size: 17px;
//   font-family: Raleway;
//   border: 1px solid #aaaaaa;
// }

// /* Mark input boxes that gets an error on validation: */
// input.invalid {
//   background-color: #ffdddd;
// }

// /* Hide all steps by default: */
// .tabb {
//   display: none;
// }

// button {
//   background-color: #04AA6D;
//   color: #ffffff;
//   border: none;
//   padding: 10px 20px;
//   font-size: 17px;
//   font-family: Raleway;
//   cursor: pointer;
// }

// button:hover {
//   opacity: 0.8;
// }

// #prevBtn {
//   background-color: #bbbbbb;
// }

/* Agrement begings */
.agrement {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 3rem;
}

.agrement_header {
  margin: auto;
  padding-top: 2rem;

  h6 {
    text-align: center;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: $black;
  }
}

.agrement__body {
  padding: 1.5rem 0.5rem 0 0.5rem;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 41px;
  align-items: center;
  color: $black;
  text-align: left;
}

.agreement__btn {
  padding-bottom: 1rem;

  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: none;
  }
}

.checkBoxx {
  color: #dc3545;
  font-family: Gotham;
  text-align: left;
  padding-top: 0.5rem;
  // margin-bottom: 0;
}

.agg-holder {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1rem;
}

.agg-label {
  text-align: left;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.8);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c6c6c;
}

// .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
//     background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='red' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
// }
// .custom-control-input:active~.custom-control-label::before{
//   // background-color:green;
// }

.opt-head {
  border-bottom: none;
}

.modal-body {
  .modal_veri_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $black;
  }

  .enter_code_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    padding-bottom: 2rem;
  }
}

.otp-holder {
  display: flex;
  justify-content: space-evenly;

  input {
    width: 80px;
    border: none;
    border-bottom: 2px solid #000000;
    border-radius: 0;
    box-shadow: none;
  }
}

.otp__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #28a745;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp__btn {
  padding: 3rem 10px;

  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    max-width: 377px;
    width: 100%;
    max-height: 45px;
    height: auto;
    box-shadow: none;
    margin-bottom: 2rem;
  }

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;

    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

/* Agrement ends */

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #000000;
  opacity: 0.8;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #000000;
  opacity: 0.8;
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #000000;
  opacity: 0.8;
}

@media screen and (max-width: 599px) {
  .nav-link {
    padding: 1rem 0.9rem;
    font-size: 11px;
  }

  .no-tours {
    font-size: 18px;
    padding-bottom: 2rem;
  }

  .movein-details {
    margin-top: 2rem;
  }

  .schedule__tour {
    text-align: center;
  }

  .warning {
    padding: 1rem 1rem 0 1rem;

    p {
      padding: 0 0 0 15px;
      text-align: left;
    }

    i {
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }
  }

  .property__details {
    p:first-child {
      padding-top: 0.7rem;
    }
  }

  .rent-value {
    padding-left: 0px;
    padding-right: 0px;
  }

  .radio-wrap {
    width: 100%;
    margin-bottom: 1.5rem;

    div {
      display: flex;
      margin-left: 10px;
    }
  }

  .nav-link.active {
    font-size: 13px;
  }

  .requirement {
    padding: 20px 0rem 6px 0;
  }

  .summary__details {
    padding-top: 1rem;
  }

  .summary__details {
    h3 {
      font-size: 12px;
    }

    small {
      font-size: 12px;
    }
  }

  .paynow {
    button {
      width: auto;
    }
  }

  .funds_wrap {
    margin: 40px 0px;
  }

  .empty__div {
    p:nth-child(3) {
      font-size: 13px;
    }
  }

  .payment-header {
    font-size: 15px;
  }

  .step {
    width: 12px;
    height: 12px;
  }

  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }

  .agrement__body {
    font-size: 12px;
    line-height: 25px;
  }

  .agg-label {
    font-size: 11px;
    line-height: 15px;
  }

  .agreement__btn {
    button {
      font-size: 16px;
    }
  }

  .second__number {
    padding-top: 2rem;
  }

  .sheltaAccount__details {
    div {
      margin: 5px 15px;

      p:first-child {
        font-size: 13px;
        margin-bottom: 0;
      }

      p:nth-child(2) {
        font-size: 13px;
        margin-bottom: 0;
      }
    }
  }

  .info__text {
    text-align: justify;
  }

  .desclaimer__text {
    padding-top: 1rem;

    p {
      font-size: 13px;
      line-height: 25px;
    }
  }

  .info__text {
    font-size: 13px;
    line-height: 25px;
    text-align: left;
  }

  .dateOf__payment {
    label {
      font-size: 13px;
    }
  }

  .upload__file {
    .dropzone {
      padding: 15px 5px;
      min-height: 146px;

      img {
        width: 6rem;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }

  .call-btn {
    font-size: 13px;
    padding: 6px 34px;

    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }

  .third-step {
    img {
      width: 7rem;
    }
  }

  .input-file {
    height: 123px;
  }

  .otp-holder {
    input {
      width: 40px;
    }
  }

  .modal-body {
    p:first-child {
      // line-height: 20px;
      margin-bottom: 11px;
    }
  }

  .otp__btn {
    p {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .nav-link.active {
    font-size: 11px;
  }

  .nav-link {
    font-size: 9px;
  }

  .funds_wrap {
    margin: 40px 0px;
  }

  .funds_options {
    margin: 10px 0px;
  }
}
</style>
